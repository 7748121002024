export default {
  title: {
    en: 'Odds Slippage Tolerance',
    cn: '可接受的赔率滑点',
  },
  custom: {
    en: 'Custom',
    cn: '普通',
  },
}
