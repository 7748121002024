export default {
  title: {
    en: 'Oops..',
    cn: 'Oops..',
  },
  text: {
    en: 'Something went wrong. Please try again later.',
    cn: '发生错误，请稍后再试',
  },
}
