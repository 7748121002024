export default {
  possibleWin: {
    en: 'Possible win:',
    cn: '可以赢：',
  },
  success: {
    title: {
      en: 'Your bet has been successfully placed, you can find it in the "My bets" tab',
      cn: '您的已成功下注，您可以在“我的投注”选项卡中找到它',
    },
  },
}
