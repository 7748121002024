export default {
  locked: {
    en: 'Outcome removed or suspended',
    cn: '结果被删除或暂停',
  },
  possibleWin: {
    en: 'Possible win',
    cn: '可能赢',
  },
}
