import { BetslipDisableReason } from '@azuro-org/sdk'


export default {
  empty: {
    title: {
      en: 'Betslip is empty',
      cn: '投注单为空',
    },
    text: {
      en: 'To add a bet to your betslip, choose a market and make your selection',
      cn: '请选择一个赛事，添加到您的投注单进行投注',
    },
  },
  settings: {
    en: 'Settings',
    cn: '设置',
  },
  single: {
    en: 'Single bet',
    cn: '单独投注',
  },
  batch: {
    en: 'Single bets ({count})',
    cn: '单独投注 ({count})',
  },
  combo: {
    en: 'Combo ({count})',
    cn: '组合 ({count})',
  },
  betAmount: {
    en: 'Bet amount',
    cn: '投注金额',
  },
  totalBet: {
    en: 'Total bet',
    cn: '总投注',
  },
  warnings: {
    [BetslipDisableReason.ComboWithForbiddenItem]: {
      en: 'One or more conditions can\'t be used in combo',
      cn: '一个或多个条件冲突，不能添加到组合中',
    },
    [BetslipDisableReason.BetAmountGreaterThanMaxBet]: {
      en: 'The maximum allowable bet amount is capped at {maxBet} {symbol}.',
      cn: '最大投注金额为 {maxBet} {symbol}.',
    },
    [BetslipDisableReason.BetAmountLowerThanMinBet]: {
      en: 'The minimum allowable bet amount is capped at {minBet} {symbol}.',
      cn: '最小投注金额为 {minBet} {symbol}.',
    },
    [BetslipDisableReason.ComboWithLive]: {
      en: 'Live outcome can\'t be used in combo',
      cn: '实时结果无法组合投注',
    },
    [BetslipDisableReason.ConditionStatus]: {
      en: 'One or more outcomes have been removed or suspended. Review your betslip and remove them.',
      cn: '一项或多项投注已被删除或暂停。检查您的投注单并将其删除后重新投注。',
    },
    [BetslipDisableReason.PrematchConditionInStartedGame]: {
      en: 'Game has started',
      cn: '比赛已经开始',
    },
    [BetslipDisableReason.ComboWithSameGame]: {
      en: 'Combo with outcomes from same game prohibited, please use Batch bet',
      cn: '禁止在同一赛事中组合投注，请使用分开多次进行投注',
    },
    [BetslipDisableReason.BatchWithLive]: {
      en: 'Live outcome can\'t be used in batch',
      cn: '无法在批量投注使用实时结果',
    },
    [BetslipDisableReason.FreeBetWithLive]: {
      en: 'FreeBet can\'t be used for live',
      cn: '免费投注不能在实时赛事中使用',
    },
    [BetslipDisableReason.FreeBetWithCombo]: {
      en: 'FreeBet can\'t be used for combo',
      cn: '免费投注不能在组合投注中使用',
    },
    [BetslipDisableReason.FreeBetWithBatch]: {
      en: 'FreeBet can\'t be used for batch',
      cn: '免费投注不能在批量投注中使用',
    },
    [BetslipDisableReason.FreeBetExpired]: {
      en: 'FreeBet is expired',
      cn: '免费投注已过期',
    },
    [BetslipDisableReason.FreeBetMinOdds]: {
      en: 'Odds\'s too low for FreeBet',
      cn: '免费投注的赔率太低',
    },
  },
}
