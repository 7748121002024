export default {
  betslip: {
    en: 'Betslip',
    cn: '投注单',
  },
  myBets: {
    en: 'My bets',
    cn: '我的投注',
  },
}
