export default {
  balance: {
    en: 'Balance',
    cn: '金额',
  },
  inBets: {
    en: 'In bets',
    cn: '投注中',
  },
  toRedeem: {
    en: 'To redeem',
    cn: '待赎回',
  },
}
