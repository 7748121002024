export default {
  nav: {
    docs: {
      en: 'About',
      cn: '关于我们',
    },
    terms: {
      en: 'Terms & Conditions',
      cn: '条款及条件',
    },
    policy: {
      en: 'Privacy Policy',
      cn: '隐私政策',
    },
    faq: {
      en: 'FAQ',
      cn: 'FAQ',
    },
  },
}
