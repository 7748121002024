export default {
  title: {
    en: 'Accepted ({count})',
    cn: '已确认 ({count})',
  },
  others: {
    en: '& Other',
    cn: '& 其他',
  },
  freebet: {
    en: 'Freebet',
    cn: '免费投注',
  },
  events: {
    en: 'events:',
    cn: '赛事',
  },
  combo: {
    en: 'Combo',
    cn: '组合',
  },
  buttonTitle: {
    en: 'More details',
    cn: '详情',
  },
  allBets: {
    en: 'All Bets',
    cn: '已投注',
  },
  empty: {
    title: {
      en: 'No accepted bets',
      cn: '未确认的投注',
    },
    text: {
      en: 'All accepted bets will be listed here',
      cn: '所有已确认的投注列表',
    },
  },
}
