export default {
  myBets: {
    en: 'My bets',
    cn: '我的投注',
  },
  disconnect: {
    en: 'Disconnect',
    cn: '断开连接',
  },
  wave: {
    title: {
      en: 'Azuro Wave Points',
      cn: '奖励积分',
    },
    text: {
      en: 'Level: {level}',
      cn: '等级：{level}',
    },
  },
}
