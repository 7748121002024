export default {
  ok: {
    en: 'Ok',
    cn: 'Ok',
  },
  connectWallet: {
    en: 'Connect wallet',
    cn: '连接钱包',
  },
  loading: {
    en: 'Loading',
    cn: '加载中',
  },
  approve: {
    en: 'Approve',
    cn: '授权',
  },
  close: {
    en: 'Close',
    cn: '关闭',
  },
  tryAgain: {
    en: 'Try again',
    cn: '再试一次',
  },
  placeBet: {
    en: 'Place a bet',
    cn: '下注',
  },
  okay: {
    en: 'Okay',
    cn: 'OK',
  },
  gotIt: {
    en: 'Got it',
    cn: '好的',
  },
}
