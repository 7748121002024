export default {
  apply: {
    en: 'Apply Bonus',
    cn: '申请奖金',
  },
  freebet: {
    en: 'Freebet',
    cn: '免费投注',
  },
}
