export default {
  title: {
    en: 'All Sports',
    cn: '所有赛事',
  },
  top: {
    en: 'Top Events',
    cn: 'TOP 赛事',
  },
}
